import React from 'react'
import SinglePostComponent from '../blog/singlePostComponent'


const SinglePost = () => (
  <div>
    <h1>BALLS</h1>
    <SinglePostComponent />
  </div>
)

export default SinglePost