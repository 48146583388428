import React from 'react';
import Write from '../blog/writePost';



function CreatePost() {

  return (
    <div>
      <head>Create New Post</head>
      <Write />
    </div>
  )
}

export default CreatePost
